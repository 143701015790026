import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../../components/_App/layout'
import Seo from '../../components/_App/seo'
import TopHeader from '../../components/Common/TopHeader'
import Navbar from '../../components/_App/Navbar'
import Footer from '../../components/_App/Footer'
import ProductBanner from '../../components/Products/ProductBanner'
import Why from '../../components/Products/Why'
import What from '../../components/Products/What'
import TalkArea from '../../components/Common/TalkArea'
import FaqArea from '../../components/FAQ/FaqArea'

import Data from '../../components/Data/Data'

const mhubFeaturesQuery = graphql`
  query GetMHubFeatureDetails {
    feature: strapiProductFeature(productId: { eq: "marketplacehub" }) {
      title
      helpText
      featureCard {
        icon
        shortDesc
        title
      }
    }
  }
`

const MarketplaceHub = () => {
  const {
    feature: { helpText, title, featureCard },
  } = useStaticQuery(mhubFeaturesQuery)

  return (
    <Layout>
      <Seo
        title="Marketplace Hub - All in One"
        description="Seamless Integration. Endless Possibilities. Empower Your Business with Our Marketplace Hub!"
        image="https://res.cloudinary.com/innoage/image/upload/v1653573151/large_home_3_d6be8e5fe6.png"
      />

      <TopHeader />

      <Navbar />

      <ProductBanner
        image="https://res.cloudinary.com/innoage/image/upload/v1684654786/large_marketplacehub_b5b5b302ff.png"
        name="Marketplace Hub - All in One"
        description="Seamless Integration. Endless Possibilities. Empower Your Business with Our Marketplace Hub!"
      />

      <Why why={Data.whyMHub} />

      <What helpText={helpText} title={title} featureCard={featureCard} />

      <TalkArea />

      <FaqArea
        description="We at InnoAge always believe in Transparency with our clients & employees, please check
            list of frequently asked questions. Feel free to ask if this doesn't help."
        faq1={Data.faqmhub1}
        faq2={Data.faqmhub2}
      />

      <Footer />
    </Layout>
  )
}

export default MarketplaceHub
